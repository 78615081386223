@layer base {
  .main {
  display: grid;
  position: relative;
}

.context {
  display: flex;
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  left: 1rem;
  flex-direction: column;
}

.title p {
  margin: 0;
}

.labels {
  margin: 0;
  padding: 0;
}

.label {
  display: block;
  padding-bottom: var(--spacer-300);
  transition: all 0.5s ease-in-out;
  opacity: 0.5;
  background: linear-gradient(-8deg, rgb(157 44 255) 30%, rgb(0 210 0) 100%);
  background-clip: text;
  color: transparent;
  font-family: var(--fontHeading);
  font-size: var(--subhead);
  font-weight: var(--black);
  line-height: var(--subheadLineHeight);
}

.label.current {
  opacity: 1;
  color: var(--color-white);
}

.slide {
  position: relative;
}

@media (--medium-and-less-only) {
  .main {
    grid-template-rows: repeat(3, auto);
  }

  .context {
    top: 8px;
    grid-column-start: 1;
    grid-row: 1 / span 3;
    justify-content: space-between;
    width: 100%;
    max-height: 100vh;
    max-height: 100dvh;
  }

  .context::before {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: calc(var(--spacer-100) * -1);
    left: 0;
    width: 100%;
    height: 100px;
    background: var(--color-black);
    /* stylelint-disable-next-line */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%);
  }

  .context::after {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background: var(--color-black);
    /* stylelint-disable-next-line */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%);
  }

  .title {
    padding-top: var(--spacer-200);
  }

  .slide {
    grid-column-start: 1;
    width: 100%;
    padding-top: 100px;
    text-align: center;
  }

  .slide:nth-of-type(1) {
    grid-row-start: 1;
  }

  .slide:nth-of-type(2) {
    grid-row-start: 2;
  }

  .slide:nth-of-type(3) {
    grid-row-start: 3;
  }

  .slide:nth-of-type(3) img {
    margin-bottom: 43vh;
  }

  .slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 50vh;
  }
}

@media (--large-viewport) {
  .main {
    grid-template-columns: 50% 50%;
  }

  .context {
    top: 1rem;
    grid-column: 1 / 2;
    justify-content: center;
    height: calc(100vh - 2rem);
    overflow: hidden;
    gap: var(--spacer-400);
  }

  .title {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .label {
    padding-bottom: var(--spacer-400);
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .slide {
    grid-column: 2 / 2;
    height: 100vh;
    height: 100dvh;
    text-align: right;
  }

  .slide img {
    position: absolute;
    top: 50%;
    right: 1rem;
    max-width: calc(100% - 2rem);
    height: auto;
    transform: translate(0, -50%);
  }
}

@media (--large-viewport) and (height <= 700px) {
  .label {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}