@layer modules, ui, base;
@layer base {
  .ThreeSlidesScroll_main__uk2Ad {
  display: grid;
  position: relative;
}

.ThreeSlidesScroll_context__X1Rgk {
  display: flex;
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  left: 1rem;
  flex-direction: column;
}

.ThreeSlidesScroll_title__rCAlr p {
  margin: 0;
}

.ThreeSlidesScroll_labels__XNwdF {
  margin: 0;
  padding: 0;
}

.ThreeSlidesScroll_label__HgJE_ {
  display: block;
  padding-bottom: var(--spacer-300);
  transition: all 0.5s ease-in-out;
  opacity: 0.5;
  background: linear-gradient(-8deg, rgb(157 44 255) 30%, rgb(0 210 0) 100%);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  font-family: var(--fontHeading);
  font-size: var(--subhead);
  font-weight: var(--black);
  line-height: var(--subheadLineHeight);
}

.ThreeSlidesScroll_label__HgJE_.ThreeSlidesScroll_current__Fu8lx {
  opacity: 1;
  color: var(--color-white);
}

.ThreeSlidesScroll_slide__VIwDe {
  position: relative;
}

@media (max-width: 63.999rem) {
  .ThreeSlidesScroll_main__uk2Ad {
    grid-template-rows: repeat(3, auto);
  }

  .ThreeSlidesScroll_context__X1Rgk {
    top: 8px;
    grid-column-start: 1;
    grid-row: 1 / span 3;
    justify-content: space-between;
    width: 100%;
    max-height: 100vh;
    max-height: 100dvh;
  }

  .ThreeSlidesScroll_context__X1Rgk::before {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: calc(var(--spacer-100) * -1);
    left: 0;
    width: 100%;
    height: 100px;
    background: var(--color-black);
    /* stylelint-disable-next-line */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%);
  }

  .ThreeSlidesScroll_context__X1Rgk::after {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background: var(--color-black);
    /* stylelint-disable-next-line */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%);
  }

  .ThreeSlidesScroll_title__rCAlr {
    padding-top: var(--spacer-200);
  }

  .ThreeSlidesScroll_slide__VIwDe {
    grid-column-start: 1;
    width: 100%;
    padding-top: 100px;
    text-align: center;
  }

  .ThreeSlidesScroll_slide__VIwDe:nth-of-type(1) {
    grid-row-start: 1;
  }

  .ThreeSlidesScroll_slide__VIwDe:nth-of-type(2) {
    grid-row-start: 2;
  }

  .ThreeSlidesScroll_slide__VIwDe:nth-of-type(3) {
    grid-row-start: 3;
  }

  .ThreeSlidesScroll_slide__VIwDe:nth-of-type(3) img {
    margin-bottom: 43vh;
  }

  .ThreeSlidesScroll_slide__VIwDe img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 50vh;
  }
}

@media (min-width: 64rem) {
  .ThreeSlidesScroll_main__uk2Ad {
    grid-template-columns: 50% 50%;
  }

  .ThreeSlidesScroll_context__X1Rgk {
    top: 1rem;
    grid-column: 1 / 2;
    justify-content: center;
    height: calc(100vh - 2rem);
    overflow: hidden;
    gap: var(--spacer-400);
  }

  .ThreeSlidesScroll_title__rCAlr {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .ThreeSlidesScroll_label__HgJE_ {
    padding-bottom: var(--spacer-400);
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .ThreeSlidesScroll_slide__VIwDe {
    grid-column: 2 / 2;
    height: 100vh;
    height: 100dvh;
    text-align: right;
  }

  .ThreeSlidesScroll_slide__VIwDe img {
    position: absolute;
    top: 50%;
    right: 1rem;
    max-width: calc(100% - 2rem);
    height: auto;
    transform: translate(0, -50%);
  }
}

@media (min-width: 64rem) and (max-height: 700px) {
  .ThreeSlidesScroll_label__HgJE_ {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
